import cx from "clsx";
import { navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Config } from "../../config";
import { Delimiter } from "../../delimiter";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Button from "../../partials/Button";
import DefinitionList from "../../partials/DefinitionList";
import Footer from "../../partials/Footer";
import PageTitle from "../../partials/PageTitle";
import PaymentMethodInformation from "../../partials/PaymentMethodInformation";
import ProgressIndicator from "../../partials/ProgressIndicator";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { CalcPrice } from "../../partials/calcPrice";
import { CheckUser } from "../../partials/checkUser";
import { ValidateUser } from '../../partials/validateUser';
import PaymentDetail from "./Detail";
import styles from "./index.module.sass";
export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);

function Payment(props) {
  const [user, setUser] = useState({});
  const [alertToList, setAlertToList] = useState([]);
  const [alertToId, setAlertToId] = useState("");
  const [alertTo, setAlertTo] = useState(null);
  const [list, setList] = useState([]);
  const [isShowingUsageFeeDetail, setIsShowingUsageFeeDetail] = useState(false);
  const [currentPriceData, setCurrentPriceData] = useState({});
  const [cardNumber, setCardNumber] = useState(props.cardNumber || "");
  const [cardYear, setCardYear] = useState(props.cardYear || "");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [carrier, setCarrier] = useState("");
  const [historyData, setHistoryData] = useState(null);
  const [paymentChecked, setPaymentChecked] = useState(false);
  const [totalFee, setTotalFee] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [gmoParams, setGmoParams] = useState({});

  useEffect(() => {
    if (Object.keys(currentPriceData).length > 0) {
      const newTotalFee =
        parseInt(currentPriceData.base_fee_current) +
        parseInt(currentPriceData.current_fee) +
        parseInt(currentPriceData.phone_fee) +
        parseInt(currentPriceData.sms_fee);
      setTotalFee(newTotalFee);
      setTotalTax(CalcPrice.taxFromTaxIncluded(newTotalFee));
    }
  }, [currentPriceData]);

  const fetchPriceData = async () => {
    const session = await props.Auth.currentSession();
    const data = await props.API.get(
      "PriceData",
      `/current_fee?start_at=${moment().format("YYYY-MM-DD")}`,
      {
        headers: { Authorization: session.idToken.jwtToken },
      }
    );
    setCurrentPriceData(data);
  };

  const fetchBilling = async () => {
    const session = await props.Auth.currentSession();
    try {
      const response = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "list-cards",
        },
      });
      // ここをセットするとカード情報が表示される
      if (response && response[0] && response[0].cardNo) {
        setCardNumber(response[0].cardNo);
        setCardYear(response[0].expire);
        setPaymentMethod("credit");
      }
    } catch(e) {
      // GMO上にカードのデータがあった場合にここに来てしまう
      // 握り潰して大丈夫
    }
  };

  const fetchPayments = async () => {
    const session = await props.Auth.currentSession();
    const carrierResponse = await props.API.get("Purchase", "/payments", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    if (carrierResponse.length > 0) {
      setCardNumber(carrierResponse[0].carrier);
      setCarrier(carrierResponse[0].carrier);
      if (carrierResponse[0].carrier == 'GMO掛け払い') {
        setGmoParams(carrierResponse[0].gmo_params);
        setPaymentMethod("GMO掛け払い");
      } else if (carrierResponse[0].cardNo == null) {
        setPaymentMethod("carrier");
      }
    }
    setTimeout(() => {
      setPaymentChecked(true);
    }, 500);
  };

  const fetchBillingList = async () => {
    const session = await props.Auth.currentSession();
    const newList = await props.API.get("GetBilling", "/billing", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    setList(newList.reverse());
  };

  const onload = async () => {
    let user = await CheckUser(props.Auth);
    if (user) {
      setUser(user);
      await Promise.all([
        fetchPriceData(),
        fetchBilling(),
        fetchPayments(),
        fetchBillingList()
      ]);
    } else {
      navigate("/", false);
    }
  };

  const Invoice = ({ title, description, fee, free }) => {
    return (
      <div className={styles.systemUsageInvoiceArea}>
        <div className={styles.name}>
          {title}
          <br />
          {description}
        </div>
      </div>
    );
  };

  const List = ({ title, description, fee, free }) => {
    return (
      <div className={styles.systemUsageFeeArea}>
        <div className={styles.name}>
          {title}
          <br />
          {description}
        </div>
        {free ? (
          <div className={styles.price}>
            <s>{fee}</s>
          </div>
        ) : (
          <div className={styles.price}>{fee}</div>
        )}
      </div>
    );
  };

  useDidMount(async () => {
    onload();
  });

  if (!user.attributes) {
    return <div />;
  }

  if (historyData != null) {
    return (
      <PaymentDetail
        {...props}
        setHistoryData={setHistoryData}
        history={historyData}
      />
    );
  }

  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
      <Wrap>
        <PageTitle title="お支払い" backURL="/menu" />
        {currentPriceData["base_fee_current"] == null && (
          <ProgressIndicator gutter={true} />
        )}
        {currentPriceData["base_fee_current"] != null && (
          <div className={styles.boxArea}>
            <div className={styles.thisMonthPayment}>
              <div className={styles.thisMonthPaymentLabel}>
                今月の利用料（予定）
              </div>
              <div className={styles.thisMonthPaymentLabelNote}>
                {/* TODO:当日の日付がMM月1日の場合は「〜
                {moment().format('MM月DD日')}」を非表示にしたいです。 */}
                {moment().format('MM月01日')}〜
                {moment().format('MM月DD日')}のご利用分
                <div className={styles.thisMonthPaymentLabelNoteDetail}>※月末に金額が確定します</div>
              </div>
              <div className={styles.thisMonthPaymentDetail}>
                ¥{Delimiter(paymentMethod === "GMO掛け払い" ? (totalFee + CalcPrice.gmoChargeWithTax) : totalFee)}
              </div>
              <div className={styles.thisMonthPaymentTax}>
                （内消費税{CalcPrice.showTaxRate()}
                <span className={styles.thisMonthPaymentTaxDetail}>
                  ¥{Delimiter(paymentMethod === "GMO掛け払い" ? (totalTax + CalcPrice.gmoChargeTax) : totalTax)}）
                </span>
              </div>
              {!isShowingUsageFeeDetail && (
                <div
                  className={styles.detailButtonWrap}
                  onClick={() => setIsShowingUsageFeeDetail(true)}
                >
                  <div className={styles.detailButton}>詳細を表示</div>
                  <div className={styles.detailButtonIcon}>
                    <img
                      src="/assets/images/icon-arrow-open.png"
                      width="12"
                      alt="詳細を表示アイコン"
                    />
                  </div>
                </div>
              )}
              {isShowingUsageFeeDetail && (
                <div
                  className={styles.detailButtonWrap}
                  onClick={() => setIsShowingUsageFeeDetail(false)}
                >
                  <div className={styles.detailButton}>詳細を隠す</div>
                  <div className={cx(styles.detailButtonIcon, styles.close)}>
                    <img
                      src="/assets/images/icon-arrow-open.png"
                      width="12"
                      alt="詳細を表示アイコン"
                    />
                  </div>
                </div>
              )}
              {/* 利用料の詳細 */}
              {isShowingUsageFeeDetail && (
                <div className={styles.usageFeeAreaWrap}>
                  <div className={styles.usageFeeDetailWrap}>
                    <div className={styles.usageFeeDetailTitle}>
                      システム利用料
                      <span>現時点までの確定分</span>
                    </div>
                    <div className={styles.systemUsageFeeWrap}>
                      <List
                        title="親機ルーター利用料"
                        description={`¥${CalcPrice.routerDataFee} × ${currentPriceData["current_active_routers"].length}台`}
                        fee={`¥${Delimiter(
                          currentPriceData["current_active_router_fee"]
                        )}`}
                      />
                      <List
                        title="子機センサー利用料"
                        description={`¥${CalcPrice.deviceDataFee} × ${currentPriceData["current_active_devices"].length}台`}
                        fee={`¥${Delimiter(
                          currentPriceData["current_active_device_fee"]
                        )}`}
                      />
                      <List
                        title="親機ルーター（停止）利用料"
                        description={`¥${CalcPrice.routerInactiveFee} × ${currentPriceData["current_inactive_routers"].length}台`}
                        fee={`¥${Delimiter(
                          currentPriceData["current_inactive_router_fee"]
                        )}`}
                      />
                      <List
                        title="子機センサー（停止）利用料"
                        description={`¥${CalcPrice.deviceInactiveFee} × ${currentPriceData["current_inactive_devices"].length}台`}
                        fee={`¥${Delimiter(
                          currentPriceData["current_inactive_device_fee"]
                        )}`}
                      />
                    </div>
                  </div>
                  <div className={styles.usageFeeDetailWrap}>
                    <div className={styles.usageFeeDetailTitle}>
                      通知料金
                      <span>現時点までの確定分</span>
                    </div>
                    <div className={styles.systemUsageFeeWrap}>
                      <List
                        title="SMS通知料"
                        fee={`¥${Delimiter(currentPriceData["sms_fee"])}`}
                      />
                      <List
                        title="電話通知料"
                        fee={`¥${Delimiter(currentPriceData["phone_fee"])}`}
                      />
                    </div>
                  </div>
                  <div className={styles.usageFeeDetailWrap}>
                    <div className={styles.usageFeeDetailTitle}>
                      監視料金
                      <span>現在の設定を元に、月末までの予想金額を算出</span>
                    </div>
                    <div className={styles.systemUsageFeeWrap}>
                      {false && (
                        <>
                          現在、料金はかかりません。課金開始時に改めてお知らせします。
                        </>
                      )}
                      <List
                        title={`監視 ¥${CalcPrice.uplinkFee} x ${
                          currentPriceData["total_uplink_count"] || 0
                        }回`}
                        fee={`¥${Delimiter(currentPriceData["current_fee"])}`}
                      />
                    </div>
                  </div>
                  <div className={styles.usageFeeDetailWrap}>
                    <div className={styles.usageFeeDetailTitle}>
                      確認料金
                      <span>現時点までの確定分</span>
                    </div>
                    <div className={styles.systemUsageFeeWrap}>
                      <List
                        title="電話での確認"
                        fee={`¥${Delimiter(
                          currentPriceData["incoming_phone_fee"]
                        )}`}
                      />
                    </div>
                  </div>
                  {paymentMethod === "GMO掛け払い" && (
                    <div className={styles.usageFeeDetailWrap}>
                      <div className={styles.usageFeeDetailTitle}>
                        手数料
                        <span>現時点までの確定分</span>
                      </div>
                      <div className={styles.systemUsageFeeWrap}>
                        <List
                          title="請求書払い決済手数料"
                          fee={`¥${Delimiter(CalcPrice.gmoChargeWithTax)}`}
                        />
                      </div>
                    </div>
                  )}
              <div className={styles.paymentDetailInfoWrap}>
                <div className={styles.paymentDetailInfoTitle}>請求情報</div>
                <div className={styles.paymentDetailInfo}>
                  <div className={styles.paymentDetailInfoLabel}>ご請求先</div>
                  <div>{user.attributes.name} 様</div>
                </div>
                <div className={styles.paymentDetailInfo}>
                  <div className={styles.paymentDetailInfoLabel}>ご請求元</div>
                  <div>{Config.companyName2}</div>
                  <div>登録番号 {Config.companyNumber2}</div>
                </div>
              </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={cx(styles.boxArea, styles.mini)}>
          <Text variant="h3" gutterBottom>
            お支払い方法
          </Text>
          {/* TODO: GMO掛け払い（実装お願いします） */}
          {paymentMethod && (
            <PaymentMethodInformation
              type={paymentMethod}
              cardNumber={cardNumber ?? undefined}
              carrier={carrier ?? undefined}
              billingAddressName={`${gmoParams?.billingAddressName || ''} 様`}
              billingAddressZipcode={gmoParams?.billingAddressZipcode || ''}
              billingAddress={`${gmoParams?.billingAddress1}${gmoParams?.billingAddress2}${gmoParams?.billingAddress3}` || ''}
              billingAddressPhoneNumber={ValidateUser.displayPhoneNumber(gmoParams?.billingAddressPhoneNumber || '')}
            />
          )}
          {paymentChecked && !paymentMethod && (
            <div className={styles.paymentMethod}>
              <div className={styles.paymentMethodArea}>
                <div className={styles.paymentMethodLabel}>
                  登録がありません
                </div>
              </div>
              <div>
                <Button size="small" onClick={() => navigate("/payment/edit")}>
                  登録
                </Button>
              </div>
            </div>
          )}
        </div>
        <Text variant="h2" gutterTop gutterBottom>
          ご請求履歴
        </Text>
        <div className={styles.paymentHistoryLists}>
          {list.map((history, idx) => {
            return (
              <div
                key={`history-${history.billing_date}`}
                onClick={() => {
                  setHistoryData(history);
                }}
                className={styles.paymentHistorylist}
              >
                <div className={styles.detail}>
                  <DefinitionList
                    title={
                      history.billing_date + " " + Delimiter(history.amount + (history.gmoFee ?? 0)) + "円"
                    }
                  >
                  </DefinitionList>
                </div>
                <div className={styles.action}>
                  <Button size="small">詳細</Button>
                </div>
              </div>
            );
          })}
        </div>
      </Wrap>
      <Footer />
    </>
  );
}

export default Payment;
