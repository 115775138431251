const moment = require('moment');
const CountUplink = (monitoringStartTime, monitoringEndTime, monitoringInterval, startDate=null, endDate=null, ignoreUntil=null, ignoreFrom=null, debug=false) => {
  if (monitoringStartTime == null) {
    throw new Error('monitoringStartTime cannot be null');    
  }
  if (monitoringEndTime == null) {
    throw new Error('monitoringEndTime cannot be null');    
  }
  if (!startDate && endDate || !endDate && startDate) {
    throw new Error('開始日か終了日のみ指定することはできません');
    return;
  }
  let start_time = moment(Date.parse(`${startDate || '2021-01-01'} ${monitoringStartTime}:00`.replace(/-/g, '/')));
  let end_time   = moment(Date.parse(`${endDate || '2021-01-01'} ${monitoringEndTime}:00`.replace(/-/g, '/')));
  const startHourMin = parseInt(monitoringStartTime.replace(/[^\d]/g, ''));
  const endHourMin = parseInt(monitoringEndTime.replace(/[^\d]/g, ''));
  let todays_end_time = moment(Date.parse(`${start_time.format('YYYY-MM-DD')}${end_time.format(' HH:mm:00')}`.replace(/-/g, '/')));
  if (start_time.isAfter(end_time)) {
    start_time = moment(Date.parse(`${startDate || '2021-01-01'} ${monitoringStartTime}:00`.replace(/-/g, '/')));
    if (!endDate) {
      end_time = moment(Date.parse(`${moment(Date.parse('2021-01-01')).add(1, 'days').format('YYYY-MM-DD')} ${monitoringEndTime}:00`.replace(/-/g, '/')));
      todays_end_time = end_time.clone();
    } else {
      end_time = moment(Date.parse(`${moment(Date.parse(endDate)).format('YYYY-MM-DD')} ${monitoringEndTime}:00`.replace(/-/g, '/')));
      todays_end_time = moment(Date.parse(`${start_time.format('YYYY-MM-DD 00:00:00')}`.replace(/-/g, '/'))).add(1, 'days');
    }
  } else if (startHourMin > endHourMin) {
    todays_end_time = moment(Date.parse(`${start_time.format('YYYY-MM-DD 00:00:00')}`.replace(/-/g, '/'))).add(1, 'days');
    end_time.endOf('month').add(1, 'days').startOf('month');
  } else if (monitoringStartTime == monitoringEndTime || start_time.isSame(end_time)) {
    start_time = moment(Date.parse(`${startDate || '2021-01-01'} ${monitoringStartTime}:00`.replace(/-/g, '/')));
    if (!endDate) {
      start_time = moment(Date.parse(`${startDate || '2021-01-01'} 00:00:00`.replace(/-/g, '/')));
      end_time  = moment(Date.parse(`${moment(Date.parse('2021-01-01')).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`.replace(/-/g, '/'))).subtract(1, 'minutes');
      todays_end_time = moment(Date.parse(`${start_time.format('YYYY-MM-DD 00:00:00')}`.replace(/-/g, '/'))).add(1, 'days');
    } else {
      start_time = moment(Date.parse(`${startDate || '2021-01-01'} 00:00:00`.replace(/-/g, '/')));
      end_time = moment(Date.parse(`${moment(Date.parse(endDate)).format('YYYY-MM-DD')} 23:59:59`.replace(/-/g, '/')));
      todays_end_time = moment(Date.parse(`${start_time.format('YYYY-MM-DD 00:00:00')}`.replace(/-/g, '/'))).add(1, 'days');
    }
  }
  let counter = 0;
  let current_time = start_time.clone();

  if (debug) {
    console.log(`${monitoringStartTime}, ${monitoringEndTime}, ${monitoringInterval}, ${startDate}, ${endDate}, ${ignoreUntil}, ${ignoreFrom}, ${debug}`);
    console.log(`start_time: ${start_time.format('YYYY-MM-DD HH:mm:ss')}`);
    console.log(`end_time: ${end_time.format('YYYY-MM-DD HH:mm:ss')}`);
    console.log(`current_time: ${current_time.format('YYYY-MM-DD HH:mm:ss')}`);
    console.log(`todays_end_time: ${todays_end_time.format('YYYY-MM-DD HH:mm:ss')}`);
    if (ignoreUntil) {
      console.log(`ignoreUntil: ${ignoreUntil.format('YYYY-MM-DD HH:mm:ss')}`);
    }
  }
  //console.log(`start ${todays_end_time.format('YYYY-MM-DD HH:mm:ss')} ${current_time.format('YYYY-MM-DD HH:mm:ss')}`);
  let state = false;
  let current_state = false;
  while(true) {
    if (current_time.isAfter(end_time)) {
      if (debug) {
        console.log(`on exit start_time: ${start_time.format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(`on exit end_time: ${end_time.format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(`on exit current_time: ${current_time.format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(`on exit todays_end_time: ${todays_end_time.format('YYYY-MM-DD HH:mm:ss')}`);
      }
      break;
    }
    if (ignoreUntil != null && current_time.isBefore(ignoreUntil)) {
      state = false;
      // next
      if (debug) {
        console.log(`ignore until current_time ${current_time.format('YYYY-MM-DD HH:mm:ss')} is before ${ignoreUntil.format('YYYY-MM-DD HH:mm:ss')}`);
      }
    } else if (ignoreFrom != null && current_time.isAfter(ignoreFrom)) {
      state = false;
      // next
      if (debug) {
        console.log(`ignore from current_time ${current_time.format('YYYY-MM-DD HH:mm:ss')} is after ${ignoreFrom.format('YYYY-MM-DD HH:mm:ss')}`);
      }
    } else if (current_time.isSameOrAfter(start_time) && current_time.isSameOrBefore(todays_end_time)) {
      if (parseInt(monitoringInterval) === 60) {
        if (current_time.format(':mm').match(/:00$/)) {
          state = true;
          counter++;
        } else {
          state = false;
        }
      } else if (parseInt(monitoringInterval) === 30) {
        if (current_time.format(':mm').match(/:00$/) || current_time.format(':mm').match(/:30$/)) {
          state = true;
          counter++;
        } else {
          state = false;
        }
      } else {
        state = true;
        counter++;
      }
    } else {
      state = false;
    }
    if (debug && state) {
      console.log(`tick ${start_time.format('YYYY-MM-DD HH:mm:ss')} ${current_time.format('YYYY-MM-DD HH:mm:ss')} ${todays_end_time.format('YYYY-MM-DD HH:mm:ss')} ${counter}`);
    }// else if (debug) {
    //  console.log(`pass ${start_time.format('YYYY-MM-DD HH:mm:ss')} ${current_time.format('YYYY-MM-DD HH:mm:ss')} ${todays_end_time.format('YYYY-MM-DD HH:mm:ss')} ${counter}`);
    //}
    if (current_time.isSameOrAfter(todays_end_time)) {
      start_time.add(1, 'days');
      todays_end_time.add(1, 'days');
    }
    current_time.add(10, 'minutes');
  }
  return counter;
};

//const start = moment(Date.parse('2021-06-18 12:35:00'));
//const end = null;//moment(Date.parse('2021-05-04 00:00:00'));
//console.log('===start====');
//console.log(`23:00 - 23:00,10,day,3, ${CountUplink('23:00', '23:00', 10, null,null,null,null,true)}`);
//console.log(`00:00 - 00:00,60,day,24 , ${CountUplink('00:00', '00:00', 60)}`);
//console.log(`01:00 - 00:00,10,day,139, ${CountUplink('01:00', '00:00', 10)}`);
//console.log(`00:00 - 00:00,10,day,144, ${CountUplink('00:00', '00:00', 10)}`);
//console.log(`00:00 - 00:00,60,month 720: ${CountUplink('00:00', '00:00', 60, '2021-06-01', '2021-06-30', null,null)}`);
//console.log(`00:00 - 01:00, 60, month, 720, ${CountUplink('00:00', '01:00', 60, '2021-06-01', '2021-06-30', moment(Date.parse('2021-06-22T06:21:50.263Z')),null)}`);
//console.log(`01:00 - 00:00, 60, month, 229, ${CountUplink('01:00', '00:00', 60, '2021-06-01', '2021-06-30', moment(Date.parse('2021-06-21 11:00:01')), null)}`);
//console.log(`00:00 - 23:50, 10, month, 4320, ${CountUplink('00:00', '23:50', 10, '2021-06-01', '2021-06-30', null, null)}`);
////console.log(`00:10 - 00:00, 10, month, 4170, ${CountUplink('00:10', '00:00', 10, '2021-06-01', '2021-06-30', null, null,true)}`);
////console.log(`01:00 - 00:00, 10, month, 1329, ${CountUplink('01:00', '00:00', 10, '2021-06-01', '2021-06-30', moment(Date.parse('2021-06-21 11:00:01')), null)}`);
//console.log('===end====');

export{CountUplink};
